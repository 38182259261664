export const fetchEventList = async (page: number, setEvents: Function) => {
  try {
    const response = await fetch(
      `/api/v1/photo-manager/contents?t=root&page=${page}`
    );
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    const meta = data.meta;
    setEvents(data.entities, meta.nextPage);
  } catch (error) {
    console.error("Failed to fetch events:", error);
  }
};

export const fetchAlbumDetails = async (eventId: number) => {
  try {
    const response = await fetch(
      `/api/v1/photo-manager/contents?t=Gallery&id=${eventId}`
    );
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    return data.entities;
  } catch (error) {
    console.error(`Failed to fetch albums for event ${eventId}:`, error);
    return [];
  }
};

export const fetchAlbumPhotos = async (albumId: number, page: number) => {
  try {
    const response = await fetch(
      `/api/v1/photo-manager/contents?t=Album&id=${albumId}&page=${page}`
    );
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error(`Failed to fetch photos for album ${albumId}:`, error);
    return [];
  }
};

export const fetchEventsByName = async (name: string) => {
  try {
    const response = await fetch(`/api/v1/photo-manager/search?q=${name}`);
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error(`Failed to fetch events with the name ${name}:`, error);
    return [];
  }
};
