import React, { Fragment, useState } from "react";
import { useEscapeKey } from "../../utils/useEscapeKey";
const PublicIcon = require("./styles/public.svg");
const unpublishedIcon = require("./styles/unpublished.svg");
const restrictedIcon = require("./styles/restricted.svg");

const PublishingOptionsPopup = ({
  publishOptionId,
  setPublishOptionId,
  passwordToggle,
  setToggle,
  passwordInput,
  setPasswordInput,
  handleConfirm,
  closePopup,
  eventUrl,
  eventName,
  schoolUser,
  loading
}: {
  publishOptionId: string;
  setPublishOptionId: (arg0: string) => void;
  passwordToggle: any;
  setToggle: (arg0: boolean) => void;
  passwordInput: string;
  setPasswordInput: (arg0: string) => void;
  handleConfirm: () => void;
  closePopup: () => void;
  eventUrl: string;
  eventName: string;
  schoolUser: boolean | null;
  loading: boolean;
}) => {
  useEscapeKey(() => closePopup());

  return (
    <div>
      <div
        id="filter-dialog"
        className="modal fade in confirm-modal-div geo-modal privacy-modal"
        style={{ overflowY: "auto" }}
        role="dialog"
      >
        <div className="modal-dialog filter-dialog-content geo-privacy-body geo-privacy-modal">
          <div className="modal-content">
            <div className="modal-header" style={{ paddingTop: 15 }}>
              <span className="modal-title geo-privacy-title">{eventName}</span>
              <button
                type="button"
                className="close confirm-close"
                onClick={() => closePopup()}
              >
                <span>&times;</span>
              </button>{" "}
            </div>

            <div className="modal-body">
              <div>
                <label className="labelset-radio">
                  <input
                    type="radio"
                    name="publishOption"
                    id="privacy-setting-unpublished"
                    value="Unpublished"
                    defaultChecked={
                      publishOptionId == "unpublished" ? true : false
                    }
                    onChange={() => setPublishOptionId("unpublished")}
                  />
                  <span className="labelset-radioinput"></span>
                  <span className="labelset-text">
                    <strong className="labelset-title">
                      Unpublished{" "}
                      <img style={{ marginLeft: 5 }} src={unpublishedIcon} />
                    </strong>
                    Only team & invited photographers can see photos in this
                    event
                  </span>
                </label>
              </div>
              <div className="geo-privacy-subbody">
                <>
                  <span className="geo-sub-header">PUBLISH</span>
                  <span className="geo-muted-info">
                    Enable access for others...
                  </span>
                  {schoolUser && (
                    <label className="labelset-radio">
                      <input
                        type="radio"
                        name="publishOption"
                        id="privacy-setting-restricted"
                        value="Restricted"
                        defaultChecked={
                          publishOptionId == "restricted" ? true : false
                        }
                        onChange={() => setPublishOptionId("restricted")}
                      />
                      <span className="labelset-radioinput"></span>
                      <span className="labelset-text">
                        <strong className="labelset-title">
                          Restricted{" "}
                          <img style={{ marginLeft: 5 }} src={restrictedIcon} />
                        </strong>
                        Only users logged into Schoolbox can access this event
                      </span>
                    </label>
                  )}
                </>

                <label className="labelset-radio">
                  <input
                    type="radio"
                    name="publishOption"
                    id="privacy-setting-anyone"
                    value="Anyone"
                    defaultChecked={publishOptionId == "public" ? true : false}
                    onChange={() => setPublishOptionId("public")}
                  />
                  <span className="labelset-radioinput"></span>
                  <span className="labelset-text">
                    <strong className="labelset-title">
                      Anyone on the internet
                      <img style={{ marginLeft: 7 }} src={PublicIcon} />
                    </strong>
                    Anyone on the internet can see all the albums & photos in
                    this event
                  </span>
                </label>

                {publishOptionId === "public" && (
                  <div className="modal-set-password">
                    <div className="password-heading">
                      <div className="popup-pass-head">
                        <span className="popup-pass-label">
                          Consider setting a password?
                        </span>
                        <span className="popup-pass-desc">
                          To increase safety of your albums & photos
                        </span>
                      </div>
                      <div className="tag-mode-tog">
                        <label className="switch geo-form__toggle">
                          <input
                            type="checkbox"
                            className="geo-form__toginput"
                            defaultChecked={
                              passwordInput != "" && passwordInput != null
                            }
                            onChange={() =>
                              passwordToggle == true
                                ? (setToggle(!passwordToggle),
                                  setPasswordInput(""))
                                : setToggle(!passwordToggle)
                            }
                          />
                          <span className="geo-form__tog">
                            <span className="geo-form__tog-in"></span>
                          </span>
                        </label>
                      </div>
                    </div>
                    {(passwordToggle ||
                      (passwordInput != "" && passwordInput != null)) && (
                      <div className="geo-pw__wrap geo-form--fw">
                        <div className="geo-spacer"></div>
                        <input
                          type="text"
                          value={passwordInput}
                          onChange={e => setPasswordInput(e.target.value)}
                          className="geo-form__input geo-round-input"
                          placeholder="Enter password"
                          required={passwordToggle}
                        />
                      </div>
                    )}
                  </div>
                )}
              </div>
              <div
                className="modal-footer privacy-modal-footer"
                style={{
                  display: "flex",
                  justifyContent: "center"
                }}
              >
                <button
                  className=" geo-privacy-btn"
                  onClick={() =>
                    navigator.clipboard.writeText(
                      `${eventUrl}`,
                      //@ts-ignore
                      toastr.success("Copied to clipboard!")
                    )
                  }
                >
                  Copy Link
                </button>
                <button
                  className="geo-btn geo-btn--secondary geo-btn--caps "
                  onClick={() => handleConfirm()}
                  style={{ padding: "12px 30px", marginLeft: "15px" }}
                  disabled={
                    publishOptionId == "public" &&
                    passwordInput == "" &&
                    passwordToggle
                      ? true
                      : false
                  }
                >
                  {loading ? (
                    <div className="loading-spinner">
                      <span className="fa fa-spinner fa-spin"></span>
                    </div>
                  ) : (
                    " SAVE"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade in" />
    </div>
  );
};

export default PublishingOptionsPopup;
