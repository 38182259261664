import PropTypes from "prop-types";
import React from "react";
import ajax from "superagent";
import _ from "lodash";
import { removeParam } from "../../../utils/BrowserHelper";
import Pluralize from "react-pluralize";

// const bagOfPhotos = require("../styles/bag-of-photos.svg");
import bagOfPhotos from "../styles/bag-of-photos.svg";

// const closeIcon = require("../styles/remove.svg");
const lockIcon = require("../styles/lock.svg");
const verifiedTick = require("../styles/verified-tick.svg");
import { Event } from "./types";

interface PrepaidCustomerAuthPopupProps {
  event: Event;
  authenticatePrepaidCustomerAPI: string;
  setPrepaidCustomer: Function;
  setUpdatedCart: Function;
  cancelPrepaidAuth: Function;
  cartId: number | null;
}

interface PrepaidCustomerAuthPopupState {
  primaryAuthValue: string | null;
  secondaryAuthValue: string | null;
  authenticating: boolean;
  authStatus: string;
  showNeedHelp: boolean;
  showSecondaryAuth: boolean;
}

class PrepaidCustomerAuthPopup extends React.Component<
  PrepaidCustomerAuthPopupProps,
  PrepaidCustomerAuthPopupState
> {
  static contextTypes = {
    cartId: PropTypes.number
  };
  primaryAuthField: React.RefObject<HTMLInputElement>;
  secondaryAuthField: React.RefObject<HTMLInputElement>;

  constructor(props: PrepaidCustomerAuthPopupProps) {
    super(props);
    this.state = {
      primaryAuthValue: "",
      secondaryAuthValue: "",
      showSecondaryAuth: false,
      authenticating: false,
      authStatus: "initial", // < initial | checking | success | unauthorized >
      showNeedHelp: false
    };

    this.primaryAuthField = React.createRef();
    this.secondaryAuthField = React.createRef();
  }

  primaryAuthFieldName = () => {
    let primaryAuth = this.props.event.prepaidCustomersPrimaryAuthField;
    if (primaryAuth == "race_number") primaryAuth = "student_number";
    return _.startCase(primaryAuth).toLocaleLowerCase();
  };

  secondaryAuthFieldName = () => {
    if (this.props.event.prepaidCustomersSecondaryAuthField == null)
      return null;

    let secondaryAuth = this.props.event.prepaidCustomersSecondaryAuthField;
    if (secondaryAuth == "race_number") secondaryAuth = "student_number";
    return _.startCase(secondaryAuth).toLocaleLowerCase();
  };

  hasTwoStepAuth = () => !!this.props.event.prepaidCustomersSecondaryAuthField;

  setPrimaryAuthValue = (callback: (() => void) | undefined) => {
    this.setState(
      {
        primaryAuthValue: this.primaryAuthField.current
          ? this.primaryAuthField.current.value.trim()
          : null,
        authStatus: "initial",
        showSecondaryAuth: this.hasTwoStepAuth()
      },
      callback
    );
  };

  setSecondaryAuthValue = (callback: (() => void) | undefined) => {
    this.setState(
      {
        secondaryAuthValue: this.secondaryAuthField.current
          ? this.secondaryAuthField.current.value.trim()
          : null
      },
      callback
    );
  };

  clearFields = () => {
    // TS doesn't allow current?.value on the LHS
    // @ts-ignore
    this.customerEmailField.current.value = null;
    // @ts-ignore
    this.secondaryAuthField.current.value = null;
    this.setState({
      primaryAuthValue: "",
      secondaryAuthValue: "",
      showSecondaryAuth: false
    });
  };

  authenticateCustomer = () => {
    this.setState({ authStatus: "checking" }, () =>
      ajax
        .post(this.props.authenticatePrepaidCustomerAPI)
        .send({
          primary_auth_value: this.state.primaryAuthValue,
          secondary_auth_value: this.state.secondaryAuthValue,
          cart_id: this.context.cartId || this.props.cartId
        })
        .then(response => {
          this.setState(
            {
              authStatus: "success"
            },
            () => {
              removeParam("prepaid-auth", window.location.href);
              setTimeout(() => {
                this.props.setPrepaidCustomer(response.body.customer);
                this.props.setUpdatedCart(response.body.updatedCart);
                //@ts-ignore
                toastr.success("You have been verified", null, 1000);
              }, 1500);
            }
          );
        })
        .catch(_ => {
          this.setState({
            authStatus: "unauthorized",
            primaryAuthValue: null,
            secondaryAuthValue: null,
            showNeedHelp: true
          });
          this.clearFields();
        })
    );
  };

  verifyPrimaryAuth = () => {
    this.setPrimaryAuthValue(() => {
      if (!this.hasTwoStepAuth()) {
        this.authenticateCustomer();
      }
    });
  };

  verifySecondaryAuth = () => {
    this.setSecondaryAuthValue(() => this.authenticateCustomer());
  };

  removePopupParam = () => removeParam("prepaid-auth", window.location.href);

  getAuthenticationStatus = () => {
    switch (this.state.authStatus) {
      case "success":
        return (
          <>
            <img src={verifiedTick} className="succes-tick" />
            <div>
              <p className="prepaid-success-msg">You have been verified</p>
            </div>
          </>
        );
      case "unauthorized":
        return (
          <div>
            <p className="prepaid-failed-msg">
              {`We could not verify your ${this.primaryAuthFieldName()} ${
                this.hasTwoStepAuth()
                  ? ` or ${this.secondaryAuthFieldName()}`
                  : ""
              }. Please try again.`}
            </p>
          </div>
        );
      default:
        return <></>;
    }
  };

  getPrepaidCustomerAuthPopupClassName = () => {
    switch (this.state.authStatus) {
      case "success":
        return "prepaid-first-input success-input";
      case "unauthorized":
        return "prepaid-first-input failed-input";
      default:
        return "prepaid-first-input";
    }
  };

  render() {
    return (
      <div>
        <div
          id="prepaid-dialog"
          className="modal fade in geo-modal geo-modal--scroll"
          role="dialog"
        >
          <div className="modal-dialog prepaid-dialog-content">
            <img src={bagOfPhotos} className="prepaid-img" />
            <button
              type="button"
              className="close prepaid-close-icon"
              onClick={_ => this.props.cancelPrepaidAuth()}
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <div className="prepaid-content-div">
              <h3 className="prepaid-header prepaid-header2">
                Did you pre-register for{" "}
                <>
                  <Pluralize
                    singular="a photo"
                    plural="the photos"
                    count={this.props.event.prepaidCustomersPhotoLimit}
                    showCount={false}
                  />
                </>{" "}
                pack?
              </h3>
              <p className="prepaid-info-p">
                If you pre-register for{" "}
                <>
                  <Pluralize
                    singular="a photo"
                    plural="the photos"
                    count={this.props.event.prepaidCustomersPhotoLimit}
                    showCount={false}
                  />
                </>{" "}
                pack, enter your details to get the{" "}
                <Pluralize
                  singular="photo"
                  plural="photos"
                  count={this.props.event.prepaidCustomersPhotoLimit}
                  showCount={false}
                />{" "}
                for free
              </p>
              <div className="prepaid-div2">
                {!this.hasTwoStepAuth() ? (
                  <img src={lockIcon} className="prepaid-lock-icon" />
                ) : (
                  <div className="prepaid-steps-div">
                    <div className="prepaid-inactive-step">
                      <p className="prepaid-step-p">1</p>
                    </div>
                    <hr
                      className={
                        this.state.showSecondaryAuth
                          ? "prepaid-inactive-step-bar"
                          : "prepaid-active-step-bar"
                      }
                    />
                    <div
                      className={
                        this.state.showSecondaryAuth
                          ? "prepaid-inactive-step"
                          : "prepaid-active-step"
                      }
                    >
                      <p className="prepaid-step-p">2</p>
                    </div>
                  </div>
                )}
                {!this.state.showSecondaryAuth ? (
                  <div>
                    <p className="prepaid-info-p prepaid-input-p">
                      Enter your {this.primaryAuthFieldName()} to proceed.
                    </p>
                    <input
                      className={this.getPrepaidCustomerAuthPopupClassName()}
                      key="primary-auth"
                      placeholder={"Your " + this.primaryAuthFieldName()}
                      ref={this.primaryAuthField}
                      onChange={e =>
                        this.setState({ primaryAuthValue: e.target.value })
                      }
                      onKeyPress={target => {
                        if (
                          target.key === "Enter" &&
                          !!this.state.primaryAuthValue?.trim()
                        ) {
                          this.verifyPrimaryAuth();
                        }
                      }}
                      autoFocus={!/Mobi/i.test(window.navigator.userAgent)}
                    ></input>
                    {this.getAuthenticationStatus()}
                    <button
                      className={
                        "prepaid-verify-btn  geo-btn geo-btn--secondary geo-btn--caps"
                      }
                      disabled={!this.state.primaryAuthValue?.trim()}
                      onClick={this.verifyPrimaryAuth}
                    >
                      <VerifyButtonText authStatus={this.state.authStatus} />
                    </button>
                  </div>
                ) : (
                  <div>
                    <p className="prepaid-info-p prepaid-input-p">
                      Please enter your {this.secondaryAuthFieldName()} to
                      proceed
                    </p>
                    <input
                      className={this.getPrepaidCustomerAuthPopupClassName()}
                      key="second-auth"
                      placeholder={`Your ${this.secondaryAuthFieldName()}`}
                      ref={this.secondaryAuthField}
                      onChange={e =>
                        this.setState({ secondaryAuthValue: e.target.value })
                      }
                      onKeyPress={target => {
                        if (
                          target.key === "Enter" &&
                          !!this.state.secondaryAuthValue?.trim()
                        ) {
                          this.verifySecondaryAuth();
                        }
                      }}
                      autoFocus={!/Mobi/i.test(window.navigator.userAgent)}
                    ></input>
                    {this.getAuthenticationStatus()}
                    <button
                      className="prepaid-verify-btn geo-btn geo-btn--secondary geo-btn--caps"
                      disabled={!this.state.secondaryAuthValue?.trim()}
                      onClick={this.verifySecondaryAuth}
                    >
                      <VerifyButtonText authStatus={this.state.authStatus} />
                    </button>
                  </div>
                )}
                {this.state.showNeedHelp && (
                  <button
                    className="prepaid-contact-btn"
                    //@ts-ignore
                    onClick={_ => Intercom("show")}
                  >
                    Need Help?
                  </button>
                )}
                <p
                  className="prepaid-cancel-p geo-btn geo-btn--link geo-btn--caps"
                  onClick={_ => this.props.cancelPrepaidAuth()}
                >
                  Cancel
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-backdrop fade in" />
      </div>
    );
  }

  componentWillMount() {
    document.getElementsByTagName("body")[0].classList.add("modal-open");
  }

  componentWillUnmount() {
    document.getElementsByTagName("body")[0].classList.remove("modal-open");
  }
}

export default PrepaidCustomerAuthPopup;

interface VerifyButtonTextProps {
  authStatus: string;
}

class VerifyButtonText extends React.Component<VerifyButtonTextProps> {
  render() {
    return (
      <React.Fragment>
        {/* To address G-translate issues, wrap text nodes with span tags to prevent errors in React. */}
        {this.props.authStatus == "checking" ? (
          <span>
            <span className="fa fa-circle-o-notch fa-spin" />
            &nbsp; <span>Please wait</span>
          </span>
        ) : this.props.authStatus == "success" ? (
          <span>Verified</span>
        ) : (
          <span>Verify</span>
        )}
      </React.Fragment>
    );
  }
}
